import React from 'react';
import sgLogoFullUrl from 'static/images/socgen_logo_full.svg';
import LogoUrl from 'static/images/Logo.svg';
import { AccountCenter } from '../../layout/accountCenter/AccountCenter';

export const Header = () => (
    <div className="header">
        <nav className="navbar navbar-expand-x1 navbar-light">
            <div className="d-flex">
                <a
                    href="https://www.sgmarkets.com/"
                    className="navbar-brand d-flex align-items-center"
                >
                    <img
                        className="d-none d-sm-block"
                        src={sgLogoFullUrl}
                        height="36"
                        width="185"
                        alt="Societe Generale"
                    />
                    <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        height="36"
                        width="36"
                        className="d-sm-none"
                    >
                        <path fill="#ed1a3b" d="M0 0h24v12.246H0z" />
                        <path fill="#231f20" d="M0 12h24v12H0z" />
                        <path fill="#fff" d="M4.19 11.272h15.611v1.457H4.19z" />
                    </svg>
                </a>
            </div>
            <div className="d-flex align-items-center order-xl-1 ">
                <AccountCenter />

                <div className="ml-3 d-none d-sm-block">
                    <img
                        className="d-block"
                        src={LogoUrl}
                        height="38"
                        width="135"
                        alt="sgmarkets"
                    />
                    <span className="font-weight-medium position-relative" style={{ top: '-5px' }}>
                        Subscription
                    </span>
                </div>
            </div>
        </nav>
    </div>
);
