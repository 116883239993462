import { Routes, Route } from 'react-router-dom';
import { Footer } from 'components/layout/footer/Footer';
import { Header } from 'components/common/header/Header';
import { ToastContainer } from 'react-toastify';
import classNames from 'classnames';
import { HelpCenter } from 'components/layout/helpCenter/HelpCenter';
import { SplashScreen } from 'components/layout/SplashScreen/SplashScreen';
import React, { lazy, Suspense } from 'react';
import { Loading } from 'components/common/Loading';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

const SubscriptionListsPage = lazy(() => import('components/pages/subscriptionListsPage/SubscriptionListsPage'));
const SubscriptionListCreateEditPage = lazy(() => import('components/pages/subscriptionListEditPage/SubscriptionListCreateEditPage'));
const SubscriptionListHistoryPage = lazy(() => import('components/pages/subscriptionListHistoryPage/SubscriptionListHistoryPage'));
const UnsubscribePage = lazy(() => import('components/pages/subscription/UnsubscribePage'));
const UnsubscribeValidatedPage = lazy(() => import('components/pages/subscription/UnsubscribeValidatedPage'));
const SubscribePage = lazy(() => import('components/pages/subscription/SubscribePage'));
const SubscribeValidatedPage = lazy(() => import('components/pages/subscription/SubscribeValidatedPage'));
const ForbiddenPage = lazy(() => import('components/pages/error/ForbiddenPage'));

export const App: React.FC = () => {
	return (
		<>
			<Header />
			<Suspense fallback={<div className="d-flex justify-content-center"><Loading /></div>}>
				<Routes>
					<Route path="/" element={<SubscriptionListsPage />} />
					<Route path="/create" element={<SubscriptionListCreateEditPage />} />
					<Route path="/edit/:id" element={<SubscriptionListCreateEditPage />} />
					<Route path="/history/:id" element={<SubscriptionListHistoryPage />} />
					<Route path="/unsubscribe/:clientSubscriptionListId" element={<UnsubscribePage />} />
					<Route path="/unsubscribe-validated" element={<UnsubscribeValidatedPage />} />
					<Route path="/subscribe/:clientSubscriptionListId" element={<SubscribePage />} />
					<Route path="/subscribe-validated" element={<SubscribeValidatedPage />} />
					<Route path="/error403" element={<ForbiddenPage />} />
				</Routes>
			</Suspense>
			<Footer />

			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				closeButton={false}
				icon={false}
				newestOnTop
				closeOnClick
				pauseOnFocusLoss
				draggable
				pauseOnHover
				toastClassName={context => classNames(
					'toast fade show',
					{
						'toast-danger': context?.type === 'error',
						'toast-info': context?.type === 'info',
						'toast-success': context?.type === 'success',
						'toast-warning': context?.type === 'warning',
						'toast-primary': context?.type === 'default',
					},
				)}
				bodyClassName={() => ''}
				progressClassName={context => classNames(
					context?.defaultClassName,
					{
						'bg-danger': context?.type === 'error',
						'bg-info': context?.type === 'info',
						'bg-success': context?.type === 'success',
						'bg-warning': context?.type === 'warning',
						'bg-primary': context?.type === 'default',
					},
				)}
				progressStyle={{ height: '3px', position: 'inherit' }}
			/>
			<HelpCenter />
			<SplashScreen />
		</>
	);
};

export default App;
